import './industry-case.less'

const IndustryCase = () => {
    return (
        <div className='industry-case'>
            <div className="industry-case-center">
                <div className="industry-case-show">
                    <img src={require('../../static/images/case_show.png')} alt="" />
                </div>
                <div className="industry-samples">
                    <div className="industry-case-sketch">
                        <div className="industry-sketch-info">
                            <div className="industry-sketch-title">设计草图展示</div>
                            <div className="industry-sketch-desc">市场调研报告，设计需求分析，人机分析多轮头脑风暴生成草图，进行3D建模做出PPT经内部审核后交付客户进行提案最终呈现给客户的高颜值，硬体验，超高预期的产品效果，通过CMF工程对产品设计的设计配色，材质与工艺整体串联分析。</div>
                        </div>
                        <div className="industry-sketch-picture">
                            <img src={require('../../static/images/industry_sketch.png')} alt="" />
                        </div>
                    </div>
                    <div className="industry-case-rendering">
                        <div className="industry-rendering-show">
                            <div className="industry-rendering-title">渲染图展示</div>
                            <div className="indurstry-rendering-desc">建模渲染，外观设计，结构设计<br />难以抵挡的视觉冲击，让产品与众不同</div>
                        </div>
                    </div>
                    <div className="industry-case-product">
                        <div className="industry-product-show">
                            <div className="industry-product-title">产品外观设计</div>
                            <div className="industry-product-desc">结构设计的重点在于优化产品的装配方式，保证外观的同时良好的控制产品的成本，并且多方面的<br/>考虑结构创新，从而体现产品差异化，经验丰富的结构设计师为您设计确保产品的落地可实施性。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryCase