import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './m-header.less'

interface navDataType {
    text: string,
    url: string
}

const navData: Array<navDataType> = [
    {
        text: '首 页',
        url: '/home'
    },
    {
        text: '电路设计',
        url: '/electronics'
    },
    {
        text: '软件开发',
        url: '/home'
    },
    {
        text: '工业设计',
        url: '/industry'
    },
    {
        text: '关于我们',
        url: '#my'
    }
];

interface headerType {
    headerData:{
        name:string,
        logoUrl:string
    }
}

const MHeader = ({headerData}:headerType) => {
    const [flag, setFlag] = useState(false)
    const HeaderNav = (): any => {
        const nav = useNavigate()
        return navData.map(item => {
            return (
                <div key={item.text} className="m-nav-item">
                    <a onClick={() => {
                        nav(item.url)
                        setFlag(!flag)
                    }} href={item.url}>{item.text}</a>
                </div>
            )
        })
    }
    return (
        <div className="m-header-box">
            <div className="m-header-title">
                <div className="m-header-log">
                    <img src={headerData.logoUrl} alt="" />
                </div>
                <div className="m-header-name">{headerData.name}</div>
            </div>
            <div className="m-header-open" onClick={()=>{setFlag(!flag)}}>
                <img src={require('../../static/images/m_header_open.png')} alt="" />
            </div>
            {flag ? <div className="m-header-nav"><HeaderNav></HeaderNav></div> : <></>}
        </div>
    )
}

export default MHeader