import React from 'react';
import {Route, Routes} from "react-router-dom";
import routes from "./routes";
import 'antd/dist/antd.css';
import './static/css/reset.less'
interface routeType{
    path:string,
    element:any
}
function App() {
  return (
      <Routes>
          {
              routes.map((item:routeType,index:number)=>{
                return (
                    <Route key={index} path={item.path} element={item.element}></Route>
                )
              })
          }
      </Routes>
  )
}

export default App;