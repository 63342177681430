import Home from "../page/home";
import Industry from "../page/industry";
import Electronics from "../page/electronics";

interface routeType{
    path:string,
    element:any
}

const routes:Array<routeType> = [
    {
        path:"/",
        element:<Home/>
    },
    {
        path:"/home",
        element:<Home/>
    },
    {
        path:"/industry",
        element:<Industry/>
    },
    {
        path:"/electronics",
        element:<Electronics/>
    }
]

export default routes