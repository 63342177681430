import Header from '../../components/header/header'
import Banner from '../../components/banner/banner'
import About from '../../components/about/about'
import IndustryBusiness from './industry-business'
import IndustryCase from './industry-case'
import Email from '../../components/email/email'
import Footer from '../../components/footer/footer'
import logo2 from '../../static/images/logo2.png'
import banner1 from "../../static/images/banner-bjt1.png";
import banner2 from "../../static/images/banner-xbj.png";
import banner3 from "../../static/images/banner-xltx.png";
import industryCompany from '../../static/images/industry_company.png'
import { Col, Row } from 'antd';
import businessBg from '../../static/images/business_area.png';
import './m-index.less';
import jg1 from '../../static/images/m-jg1.png';
import jg2 from '../../static/images/m-jg2.png';
import jg3 from '../../static/images/m-jg3.png';
import jg4 from '../../static/images/m-jg4.png';
import case1 from '../../static/images/industry_sketch.png';
import case2 from '../../static/images/industry_rendering.png';
import case3 from '../../static/images/product_appearance.png';
import caseTitle from '../../static/images/case_show.png'
import MHeader from '../../m-components/m-header/m-header'
import logo from '../../static/images/logo3.png'
import MBanner from '../../m-components/m-banner/m-banner'
import MAbout from '../../m-components/m-about/m-about'
import MPartner from '../../m-components/m-partner/m-partner'
import IndustryAbout from '../../static/images/industry_about.png'
import MFooter from '../../m-components/m-footer/m-footer'

interface bannerDataType {
    url: string;
    path: string
}

const Index = () => {
    // headerLogo
    const headerInfo: { name: string, english: string, logoUrl: string } = {
        name: '成都械心同力科技',
        english: 'XIEXINTONGLI',
        logoUrl: logo2
    }

    const bannerData: Array<bannerDataType> = [
        {
            url: banner3,
            path: '/electronics',
        },
        {
            url: banner1,
            path: '/home',
        },
        {
            url: banner2,
            path: '/industry',
        }
    ]

    const aboutInfo: { name: string, desc: string, imgUrl: string } = {
        name: '成都械心同力智能科技有限公司',
        desc: '成都械心同力智能科技有限公司（简称：械心同力科技）是一家专业从事产品工业设计、结构设计、外观设计、产品创意设计和产品样机开发的公司。我们通过以用户体验为出发点，回归产品本身，力求提供最完美的解决方案，改变行业流水线式设计,每一个项目的设计是有限期的，但是我们对产品的探索是无限期的。',
        imgUrl: industryCompany
    }

    const footerInfo: { name: string, keepRecord: string } = {
        name: '© 成都械心同力智能科技有限公司',
        keepRecord: '蜀ICP备2021029090号-1'
    }

    //移动端数据
    const mIndustryArea: Array<{ text: string, url: string }> = [
        {
            text: '外观设计',
            url: jg1
        },
        {
            text: '结构设计',
            url: jg2
        },
        {
            text: '效果图渲染',
            url: jg3
        },
        {
            text: '手机模型制作',
            url: jg4
        },
    ]

    const aboutData: { name: string, desc: string, imgUrl: string } = {
        name: '成都械心同力智能科技有限公司',
        desc: '成都械心同力智能科技有限公司（简称：械心同力科技）是一家专业从事产品工业设计、结构设计、外观设计、产品创意设计和产品样机开发的公司。我们通过以用户体验为出发点，回归产品本身，力求提供最完美的解决方案，改变行业流水线式设计,每一个项目的设计是有限期的，但是我们对产品的探索是无限期的。',
        imgUrl: IndustryAbout
    }


    return (
        <Row>
            <Col xs={0} sm={0} md={0} lg={24}>
                <div className='industry-container'>
                    <Header headerLogo={headerInfo}></Header>
                    <Banner bannerData={bannerData}></Banner>
                    <About info={aboutInfo}></About>
                    <IndustryBusiness></IndustryBusiness>
                    <IndustryCase></IndustryCase>
                    <Email></Email>
                    <Footer info={footerInfo}></Footer>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0}>
                {/* 头部 */}
                <MHeader headerData={{ name: '成都械心同力科技', logoUrl: logo }}></MHeader>
                {/* banner轮播图 */}
                <MBanner bannerData={bannerData}></MBanner>
                {/* 关于我们 */}
                <div className='about-us'>
                    <MAbout aboutData={aboutData}></MAbout>
                </div>
                {/*主营业务*/}
                <div className='m-industry-area'>
                    <div className='m-industry-area-title'>
                        <img src={businessBg} alt="" />
                    </div>
                    <div className='m-industry-area-inner'>
                        {
                            mIndustryArea.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={item.url} alt="" />
                                        <p>{item.text}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/*案例展示*/}
                <div className='m-industry-case'>
                    <div className='m-industry-case-title'>
                        <img src={caseTitle} alt="" />
                    </div>
                    <div className='m-industry-case-inner'>
                        <div className='m-industry-case-one'>
                            <img src={case1} alt="" />
                            <p>
                                <h4>设计草图展示</h4>
                                <span>市场调研报告，设计需求分析，人机分析多轮头脑风暴生成草图，进行3D建模做出PPT经内部审核后交付客户进行提案最终呈现给客户...</span>
                            </p>
                        </div>
                        <div className='m-industry-case-two'>
                            <img src={case2} alt="" />
                            <p>
                                <h4>渲染图展示</h4>
                                <span>建模渲染，外观设计</span>
                            </p>
                        </div>
                        <div className='m-industry-case-three'>
                            <img src={case3} alt="" />
                            <p>
                                <h4>产品外观设计</h4>
                                <span>结构设计的重点在于优化产品的装配方式，保证外观的同时良好的控制产品的成本</span>
                            </p>
                        </div>
                    </div>
                </div>
                {/* 合作伙伴 */}
                <div className="m-industry-cooperative">
                    <MPartner></MPartner>
                </div>
                <MFooter footerData={{name: '成都械心同力科技'}}></MFooter>
            </Col>
        </Row>
    )
}

export default Index