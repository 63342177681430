import './m-banner.less'
import { Swiper } from 'antd-mobile'
import { useNavigate } from "react-router-dom";
import Item from 'antd/lib/list/Item';

interface bannerDataType {
    url: string,
    path: string
}

interface bannerType {
    bannerData: Array<bannerDataType>
}

const MBanner = ({ bannerData }: bannerType) => {
    const nav = useNavigate()
    return (
        <div className="m-banner-box" id='my'>
            <Swiper loop autoplay indicator={() => null}>
                {
                    bannerData.map(item => {
                        return (
                            <Swiper.Item key={item.url}>
                                <div className='m-banner-items'>
                                    <img src={item.url} alt="" onClick={()=>{nav(item.path)}} />
                                </div>
                            </Swiper.Item>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}

export default MBanner