import {useEffect, useRef} from "react";

//如果是在页面最开始展现的时候需要用定时器，就调用hook
export const useSetInterval = (callback:()=>any,time:number)=>{
    let timer:{current:any} = useRef({current:null});
    useEffect(()=>{
        if(timer.current){
            clearInterval(timer.current);
        }
        timer.current = setInterval(()=>callback(),time);
    });
    return timer;
};