import './electro-case.less'

const ElectroCase = () => {
    return (
        <div className='electro-case-box'>
            <div className="electro-case-center">
                <div className="electro-case-header">
                    <img src={require('../../static/images/case_show.png')} alt="" />
                </div>
                <div className="electro-cases-show">
                    <div className="electro-case-one">
                        <img src={require('../../static/images/electro_case1.png')} alt="" />
                    </div>
                    <div className="electro-case-two">
                        <img src={require('../../static/images/electro_case2.png')} alt="" />
                    </div>
                    <div className="electro-case-three-title">
                        <div className='case-three-title'>川大高分子学院-多通道电熔测试设备</div>
                        <div className="case-three-subtitle">通过测试设备上传数据到服务器后台系统</div>
                    </div>
                    <div className="electro-case-three">
                        <img src={require('../../static/images/electro_case3.png')} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ElectroCase