import { Button, Form, Input, TextArea } from 'antd-mobile'
import { UserOutline, MailOutline, FillinOutline } from 'antd-mobile-icons';
import { useState } from 'react';
import './m-partner.less'

const MPartner = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [content, setContent] = useState('')
    const submit = () => {
        console.log(
            {
                name, email, content
            }
        )
    }
    return (
        <div className='m-partner-box'>
            <div className="m-partner-header">
                <img src={require('../../static/images/cooperative_partner.png')} alt="" />
            </div>
            <div className="m-partner-college">
                <img src={require('../../static/images/huoban.png')} alt="" />
            </div>
            <div className="m-partner-email">
                <div className="m-partner-contact">
                    <img src={require('../../static/images/contact_us.png')} alt="" />
                </div>
                <div className="m-partner-forms">
                    <div className="m-form-name">
                        <div className='m-name-prefix'>
                            <UserOutline></UserOutline>
                        </div>
                        <Input placeholder="你的名字" onChange={(e)=>{setName(e)}} />
                    </div>
                    <div className="m-form-name" style={{ marginBottom: '3.14vw' }}>
                        <div className='m-name-prefix'>
                            <MailOutline />
                        </div>
                        <Input placeholder="EMAIL" onChange={(e)=>{setEmail(e)}} />
                    </div>
                    <div className='m-form-desc'>
                        <div className='m-desc-prefix'>
                            <FillinOutline />
                        </div>
                        <TextArea rows={3} placeholder="信息内容" onChange={(e)=>{setContent(e)}}></TextArea>
                    </div>
                    <div className='m-form-confirm' onClick={() => { submit() }}>发送邮件</div>
                </div>
            </div>
        </div>
    )
}

export default MPartner