import emailBg from '../../static/images/email.png'
import './email.less'
import {Button, Form, Input} from "antd";
import {CalendarOutlined, UserOutlined,CommentOutlined} from '@ant-design/icons';
import huoBan from '../../static/images/huoban.png';
import emailsBubg from '../../static/images/home-bu-title.png'

const Email = () => {
    const [form] = Form.useForm();
    const onFinish = (v: any) => {
        console.log(v)
    }
    return (
        <div className="email-box">
            <img className='email-title' src={emailsBubg} alt=""/>
            <img className='email-bg' src={huoBan} alt=""/>
            <div className='email-main' style={{display:'flex',width:'1200px',margin:'0 auto'}}>
                <div className="email-left">
                    <img src={emailBg} alt=""/>
                </div>
                <div className="email-right">
                    <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
                        <Form.Item
                            name="name"
                            rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="您的名字"/>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <Input
                                prefix={<CalendarOutlined className="site-form-item-icon"/>}
                                placeholder="您的邮箱"
                            />
                        </Form.Item>
                        <Form.Item style={{position:"relative"}}
                                   rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <div className='email-message'>
                                <CommentOutlined />
                            </div>
                            <Input.TextArea
                                placeholder="信息内容"
                            ></Input.TextArea>
                        </Form.Item>
                        <Button type="primary">发送邮件</Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Email;