import './m-footer.less'

interface footerDataType {
    footerData: {
        name: string
    }
}

const MFooter = ({footerData}: footerDataType) => {
    return (
        <div className='m-footer-box'>
            <div className="m-footer-code">
                <img src={require('../../static/images/official_code.png')} alt="" />
            </div>
            <div className="offical-wechat">官方微信</div>
            <div className='m-footer-copyright'>版权所有©2021-2050   蜀ICP备2022028974895号<br/>{footerData.name} · 成都市金牛区盛大国际七栋一单元八楼<br/>电话：18108071931 · 邮箱：beijiate@163.com</div>
        </div>
    )
}

export default MFooter