import Item from 'antd/lib/list/Item'
import './m-about.less'

interface aboutDataType {
    aboutData: {
        name: string,
        desc: string,
        imgUrl: string
    }
}

const MAbout = ({ aboutData }: aboutDataType) => {
    return (
        <div className='m-about-box' id='my'>
            <div className="m-about-header">
                <img src={require('../../static/images/about_us.png')} alt="" />
            </div>
            <div className="m-company-picture">
                <img src={aboutData.imgUrl} alt="" />
            </div>
            <div className="m-about-desc">
                <div className="m-about-desc-title">{aboutData.name}</div>
                <div className="m-about-desc-subtitle">{aboutData.desc}</div>
            </div>
        </div>
    )
}

export default MAbout