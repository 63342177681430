import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import banner1 from "../../static/images/banner-bjt1.png";
import banner2 from "../../static/images/banner-xbj.png";
import banner3 from "../../static/images/banner-xltx.png";
import Footer from "../../components/footer/footer";
import Email from "../../components/email/email";
import aboutTitle from '../../static/images/t1.png';
import aboutBg from '../../static/images/abou-bg.png'
import './index.less';
import './m-index.less'
import logo from '../../static/images/logo1.png'
import homeCase from '../../static/images/home-case-title.png';
import homeCaseB1 from '../../static/images/home-case-b1.png';
import homeCaseB2 from '../../static/images/homeCaseB2.png';
import homeCaseB3 from '../../static/images/homeCaseB3.png';
import homeCaseB4 from '../../static/images/homeCaseB4.png';
import homeCaseLeft from '../../static/images/home-case-left.png';
import homeCaseRight from '../../static/images/home-case-rigth.png';
import { Carousel, Col, Row } from "antd";
import { CarouselRef } from "antd/es/carousel";
import homeCaseBuTitle from '../../static/images/home-business-title.png';
import homeBusinessBg1 from '../../static/images/home-business-b1.png';
import homeBusinessBg2 from '../../static/images/homeBusinessBg2.png';
import homeBusinessBg3 from '../../static/images/homeBusinessBg3.png';
import { useEffect, useRef, useState } from "react";
import { isBigInt64Array } from "util/types";
import { useNavigate } from "react-router-dom";
import { useSetInterval } from "../../Hooks";
import MHeader from '../../m-components/m-header/m-header'
import MBanner from '../../m-components/m-banner/m-banner'
import MAbout from "../../m-components/m-about/m-about"
import MPartner from "../../m-components/m-partner/m-partner";
import MFooter from "../../m-components/m-footer/m-footer";
import softCompany from '../../static/images/abou-bg.png'

interface bannerDataType {
    url: string;
    path: string
}

const time: number = 3000;

const Home = () => {
    const nav = useNavigate();
    //banner
    const bannerData: Array<bannerDataType> = [
        {
            url: banner1,
            path: '/home',
        },
        {
            url: banner2,
            path: '/industry',
        },
        {
            url: banner3,
            path: '/electronics',
        }
    ]
    //底部
    const info: { name: string, keepRecord: string } = {
        name: '© 四川倍佳特智能科技有限公司',
        keepRecord: '蜀ICP备2021029090号-1'
    }

    //案例展示banner
    let caseBanner: Array<{ url: string, path: string }> = [
        {
            url: homeCaseB1,
            path: ''
        },
        {
            url: homeCaseB2,
            path: ''
        },
        {
            url: homeCaseB3,
            path: ''
        },
        {
            url: homeCaseB4,
            path: ''
        }
    ];

    //行业涉及轮播
    const [businessBanner, setBusinessBanner] = useState<Array<{ url: string, path: string, infoTitle: string, infomation: string }>>([
        {
            url: homeBusinessBg2,
            path: '/electronics',
            infoTitle: '电路设计',
            infomation: '成都芯倍特电子科技有限公司，是一家集产品开发、生产、销售为一体正在逐步发展壮大的PCB＆FPC柔性线路板的高新企业。'
        },
        {
            url: homeBusinessBg1,
            path: '/home',
            infoTitle: '软件开发',
            infomation: '四川倍佳特智能科技有限公司，专注于软件系统开发、UI界面设计开发、APP/IOS应用开发、公众号/小程序/微官网，主要服务政府'

        },
        {
            url: homeBusinessBg3,
            path: '/industry',
            infoTitle: '工业设计',
            infomation: '成都械心同力智能科技有限公司（简称：械心同力科技）是一家专业从事产品工业设计、结构设计、外观设计、产品创意设计和产品样机开发的'
        }
    ]);

    //行业结束
    const [businessInfo, setBusinessInfo] = useState();

    let timer: { current: any } = useRef({ current: null });

    const interval = useSetInterval(() => {
        setBusinessBanner(switchBusinessBanner(businessBanner, false))
    }, time);


    useEffect(() => {
        //保存当前的定时器
        timer = interval;
    })


    let car: CarouselRef | null = null;

    //切换操作
    const switchBusinessBanner = (arr: Array<{ url: string, path: string }>, isDirection: boolean) => {
        let arr1: Array<any> = [];
        arr.forEach((item, index) => {
            if (isDirection) {
                index >= arr.length - 1 ? arr1.push(arr[0]) : arr1.push(arr[index + 1])
            } else {
                index >= arr.length - 1 ? arr1.unshift(arr[arr.length - 1]) : arr1.push(arr[index])
            }
        });
        return arr1;
    }

    //点击
    const onSwitchClick = (index: number) => {
        if (index === 1) {
            nav(businessBanner[index].path)
            return console.log('跳转')
        }
        const isDirection: boolean = businessBanner.length - 1 - index === 0;
        //点击操作时，先清楚定时器
        clearInterval(timer.current);
        setBusinessBanner(switchBusinessBanner(businessBanner, isDirection));
    }

    // 移动端关于我们数据
    const aboutData: { name: string, desc: string, imgUrl: string } = {
        name: '四川倍佳特智能科技有限公司',
        desc: '四川倍佳特智能科技有限公司，以技术与市场的迭代更新为导向，业务深入智能制造、消费零售、交通出行、医疗健康、文化创意、公共服务等，多领域延深、全行业覆盖。成都芯倍特电子科技有限公司专业从事硬件开发服务、柔性线路板FPC及软硬结合线路板、高频微波射频混合压感应印制的技术， 是一家集产品开发、生产、销售为一体的高新企业。 成都械心同力智能科技有限公司（简称：械心同力科技）是一家专业从事产品工业设计、结构设计、外观设计、产品创意设计和产品样机开发的公司。',
        imgUrl: softCompany
    }
    // 移动端商业领域数据
    const businessItemData1 = { title: '软件开发', subtitle: 'software development', imgUrl: homeBusinessBg1, desc: '四川倍佳特智能科技有限公司，专注于软件系统开发、UI界面设计开发、APP/IOS应用开发、公众号/小程序/微官网，主要服务政府机构、企业、高校、科研机构等领域。' }
    const businessItemData2 = { title: '电路设计', subtitle: 'circuit design', imgUrl: homeBusinessBg2, desc: '成都芯倍特电子科技有限公司，是一家集产品开发、生产、销售为一体正在逐步发展壮大的PCB＆FPC柔性线路板的高新企业。' }
    // 移动端案例展示数据
    const caseItemData1 = {title: '汉尔健康（体温监测管理系统）',subtitle: '医院体温监测管理系统，自动监测体温。',desc: `①护士不用每隔4小时测量患者体温,可减少白天和夜间的工作量\n② 可系统监控所有患者的体温情况；\n③ 降低感染的风险，保障护理人员的安全；\n④ 体温数据化系统化管理。`}
    const caseItemData2 = {title: '川大高分子学院-多通道电熔测试设备',subtitle: '',desc: `多通道电容测试设备为倍佳特与四川大学高分子学院联合开发项目，设备能同时测试35组传感器电容，测量范围大（0~1uF），主要用于智能机器人的局部压力传感研究。主要技术涉硬件开发、结构开发设计、软件系统开发。`}
    const caseItemData3 = {title: '特殊医疗食品搅拌系统',subtitle: '',desc: `特殊医疗食品搅拌系统，集数据导入分析，自动称量，自动搅拌灌装于一体的系统。主要用于医院营养科配制流体食品。`}
    //头部
    return (
        <Row>
            <Col xs={0} sm={0} md={0} lg={24}>
                <div className="home-box">
                    <Header headerLogo={{ name: '四川倍佳特智能科技', english: 'SICHUANBEIJIATE', logoUrl: logo }} />
                    {/*banner*/}
                    <Banner bannerData={bannerData} />

                    {/*关于我们*/}
                    <div className="home-main" id="my">
                        <div className='home-main-title'>
                            <img src={aboutTitle} alt="" />
                        </div>
                        <div className='home-main-inner'>
                            <div>
                                <img src={aboutBg} alt="" />
                            </div>
                            <div>
                                <p>四川倍佳特智能科技有限公司</p>
                                <p>四川倍佳特智能科技有限公司，以技术与市场的迭代更新为导向，业务深入智能制造、消费零售、交通出行、医疗健康、文化创意、公共服务等，多领域延深、全行业覆盖。<br />
                                    成都芯倍特电子科技有限公司专业从事硬件开发服务、柔性线路板FPC及软硬结合线路板、高频微波射频混合压感应印制的技术，
                                    是一家集产品开发、生产、销售为一体的高新企业。<br />
                                    成都械心同力智能科技有限公司（简称：械心同力科技）是一家专业从事产品工业设计、结构设计、外观设计、产品创意设计和产品样机开发的公司。
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*涉及行业*/}
                    <div className='home-business'>
                        <img className='home-business-title' src={homeCaseBuTitle} alt="" />
                        <div className="home-business-main">
                            {
                                businessBanner.map((item, index) => {
                                    return (
                                        <div className='home-business-inner' key={index}
                                            onClick={() => onSwitchClick(index)}>
                                            <img src={item.url} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*行业信息*/}
                        <div className='home-business-info'>
                            <div className='home-business-title'>
                                {businessBanner[1].infoTitle}
                            </div>
                            <div>{businessBanner[1].infomation}</div>
                        </div>
                    </div>

                    {/*案例*/}
                    <div className='home-case'>
                        <div className='home-case-title'>
                            <img src={homeCase} alt="" />
                        </div>
                        <div className='home-case-inner'>
                            <Carousel ref={(c: CarouselRef) => car = c} autoplay dots={false}>
                                {
                                    caseBanner.map((item, index) => {
                                        return (
                                            <img className='home-case-banner' src={item.url} alt="" key={index} />
                                        )
                                    })
                                }
                            </Carousel>
                            <div className='home-case-left' onClick={() => car?.prev()}>
                                <img src={homeCaseLeft} alt="" />
                            </div>
                            <div className='home-case-right' onClick={() => car?.next()}>
                                <img src={homeCaseRight} alt="" />
                            </div>
                        </div>
                    </div>

                    {/**/}
                    {/*email*/}
                    <Email />
                    {/*底部*/}
                    <Footer info={info} />
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0}>
                <div className="m-home-box">
                    <MHeader headerData={{ name: '四川倍佳特智能科技', logoUrl: logo }}></MHeader>
                    <div className="m-home-banner">
                        <MBanner bannerData={bannerData}></MBanner>
                    </div>
                    {/* 关于我们 */}
                    <MAbout aboutData={aboutData}></MAbout>
                    {/* 商业领域 */}
                    <div className="m-home-area">
                        <div className="m-home-are-header">
                            <img src={require('../../static/images/m_business_area.png')} alt="" />
                        </div>
                        <MBusinessItem businessItemData={businessItemData1}></MBusinessItem>
                        <div className="r-area-item">
                            <div className="r-area-content">
                                <div className="r-area-info">
                                    <div className="r-area-info-title">工业设计</div>
                                    <div className="r-area-info-subtitle">industrial design</div>
                                    <div className="r-area-info-desc">成都械心同力智能科技有限公司（简称：械心同力科技）是一家专业从事产品工业设计、结构设计、外观设计、产品创意设计和产品样机开发的公司。</div>
                                </div>
                                <div className="r-area-picture">
                                    <img src={require('../../static/images/homeBusinessBg3.png')} alt="" />
                                </div>
                            </div>
                        </div>
                        <MBusinessItem businessItemData={businessItemData2}></MBusinessItem>
                    </div>
                    {/* 案例展示 */}
                    <div className="m-home-cases">
                        <div className="m-case-header">
                            <img src={require('../../static/images/case_show.png')} alt="" />
                        </div>
                        <div className="m-home-case1">
                            <div className="m-case1-picture">
                                <img src={require('../../static/images/m_home_case1.png')} alt="" />
                            </div>
                            <MCaseContent caseItemData={caseItemData1}></MCaseContent>
                            <div className="m-case2-picture">
                                <img src={require('../../static/images/homeCaseB4.png')} alt="" />
                            </div>
                            <MCaseContent caseItemData={caseItemData2}></MCaseContent>
                            <div className="m-case2-picture">
                                <img src={require('../../static/images/m_home_case3.png')} alt="" />
                            </div>
                            <MCaseContent caseItemData={caseItemData3}></MCaseContent>
                        </div>
                    </div>
                    {/* 合作伙伴 */}
                    <div className="m-home-partner">
                        <MPartner></MPartner>
                    </div>
                    <MFooter footerData={{ name: '四川倍佳特智能科技' }}></MFooter>
                </div>
            </Col>
        </Row>
    )
}

const MBusinessItem = ({ businessItemData }: any) => {
    return (
        <div className="m-area-item">
            <div className="m-area-content">
                <div className="m-area-info">
                    <div className="m-area-info-title">{businessItemData.title}</div>
                    <div className="m-area-info-subtitle">{businessItemData.subtitle}</div>
                    <div className="m-area-info-desc">{businessItemData.desc}</div>
                </div>
                <div className="m-area-picture">
                    <img src={businessItemData.imgUrl} alt="" />
                </div>
            </div>
        </div>
    )
}

const MCaseContent = ({caseItemData}: any) => {
    return (
        <div className="case-contetnt-box">
            <div className="case-content-header">
                <div className="m-case-pointer"></div>
                <div className="m-case-title">{caseItemData.title}</div>
            </div>
            <div className="m-case-bold">{caseItemData.subtitle}</div>
            <div className="m-case-desc">{caseItemData.desc}</div>
        </div>
    )
}

export default Home;