import {Carousel} from "antd";
import banner1 from '../../static/images/banner-bjt1.png'
import banner2 from '../../static/images/banner-xbj.png'
import banner3 from '../../static/images/banner-xltx.png'
import {useNavigate} from "react-router-dom";

interface bannerDataType {
    url: string;
    path: string
}

interface bannerType{
    bannerData:Array<bannerDataType>
}

const Banner = ({bannerData}:bannerType) => {
    const nav = useNavigate();
    return (
        <div className="banner-box">
            <Carousel autoplay dots={false}>
                {
                    bannerData.map((item: bannerDataType, index: number) => {
                        return (
                            <img src={item.url} alt="" key={index} onClick={()=>nav(bannerData[index].path)}/>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default Banner;