import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import About from '../../components/about/about'
import ElectroBusiness from "./electro-business";
import ElectroCase from "./electro-case";
import Email from "../../components/email/email";
import Footer from "../../components/footer/footer";
import logo3 from '../../static/images/logo3.png'
import banner1 from "../../static/images/banner-bjt1.png";
import banner2 from "../../static/images/banner-xbj.png";
import banner3 from "../../static/images/banner-xltx.png";
import electroCompany from '../../static/images/electro_company.png'
import { Col, Row } from "antd";
import caseTitle from '../../static/images/business_area.png';
import './m-index.less';
import electroBg1 from '../../static/images/electro_case2.png';
import electroBg2 from '../../static/images/m-electro_case1.png';
import electroBg3 from '../../static/images/electro_case1.png';
import electroBg4 from '../../static/images/electro_case3.png';
import caseTitl2 from '../../static/images/case_show.png'
import MHeader from '../../m-components/m-header/m-header'
import MBanner from '../../m-components/m-banner/m-banner'
import MAbout from '../../m-components/m-about/m-about'
import MPartner from '../../m-components/m-partner/m-partner'
import MFooter from '../../m-components/m-footer/m-footer'


interface bannerDataType {
    url: string;
    path: string
}

//芯倍特电子科技有限公司
const Index = () => {
    const headerInfo: { name: string, english: string, logoUrl: string } = {
        name: '芯倍特电子科技有限公司',
        english: 'xinbeiteidianzikeji',
        logoUrl: logo3
    }

    const bannerData: Array<bannerDataType> = [
        {
            url: banner2,
            path: '/industry',
        },
        {
            url: banner1,
            path: '/home',
        },
        {
            url: banner3,
            path: '/electronics',
        }
    ]

    const aboutInfo: { name: string, desc: string, imgUrl: string } = {
        name: '成都芯倍特电子科技有限公司',
        desc: '成都芯倍特电子科技有限公司专业从事高精密度双面、多层刚性电路板PCB、柔性线路板FPC及软硬结合线路板、高频微波射频混合压感应印制的技术， 是一家集产品开发、生产、销售为一体正在逐步发展壮大的PCB＆FPC柔性线路板的高新企业。 客户的需要是我们进步和完善的动力，我们的目标是希望成为你们最优质的合作伙伴！',
        imgUrl: electroCompany
    }

    const footerInfo: { name: string, keepRecord: string } = {
        name: '© 成都械心同力智能科技有限公司',
        keepRecord: '蜀ICP备2021029090号-1'
    }

    return (
        <Row>
            <Col xs={0} sm={0} md={0} lg={24}>
                <div className="electro-box">
                    <Header headerLogo={headerInfo}></Header>
                    <Banner bannerData={bannerData}></Banner>
                    <About info={aboutInfo}></About>
                    <ElectroBusiness></ElectroBusiness>
                    <ElectroCase></ElectroCase>
                    <Email></Email>
                    <Footer info={footerInfo}></Footer>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0}>
                {/* 头部 */}
                <MHeader headerData={{ name: '芯倍特电子科技有限公司', logoUrl: logo3 }}></MHeader>
                {/* banner轮播图 */}
                <MBanner bannerData={bannerData}></MBanner>
                {/* 关于我们 */}
                <div className="electro-about-us" id='my'>
                    <MAbout aboutData={aboutInfo}></MAbout>
                </div>
                {/*主营业务*/}
                <div className='m-electro-area'>
                    <div className='m-electro-case-title'>
                        <img src={caseTitle} alt="" />
                    </div>
                    <div className='m-electro-area-inner'>
                        <div>
                            <img src={electroBg1} alt="" />
                        </div>
                        <div>
                            <div>
                                <p>①印刷电路板加工</p>
                                <p>②电子元器件采购</p>
                                <p>③成品组装</p>
                                <p>④电子电路设计</p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p>⑤PLC自动化</p>
                                <p>⑥嵌入式</p>
                                <p>⑦程序设计</p>
                                <p>⑧智能硬件</p>
                            </div>
                        </div>
                        <div>
                            <img src={electroBg2} alt="" />
                        </div>
                    </div>
                </div>
                {/*案例展示*/}
                <div className='m-electro-case'>
                    <div className='m-electro-case-title'>
                        <img src={caseTitl2} alt="" />
                    </div>
                    <div className='m-electro-case-inner'>
                        <div className="m-electro-case-picture">
                            <img src={electroBg3} alt="" />
                        </div>
                        <div className="m-electro-case-picture">
                            <img src={banner2} alt="" />
                        </div>
                        <div className='m-electro-case-inner-title'>
                            <p>川大高分子学院-多通道电熔测试设备</p>
                            <p>通过测试设备上传数据到服务器后台系统</p>
                        </div>
                        <img src={electroBg4} alt="" />
                    </div>
                </div>
                {/* 合作伙伴 */}
                <div className="m-electro-cooperative">
                    <MPartner></MPartner>
                </div>
                <MFooter footerData={{ name: '芯倍特电子科技有限公司' }}></MFooter>
            </Col>
        </Row>

    )
}

export default Index;

