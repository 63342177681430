import './industry-business.less'

// 六个主营业务
const areaList = [
    { key: '1', imgUrl: 'brand_anasys', desc: '品牌分析' },
    { key: '2', imgUrl: 'sample_development', desc: '样机开发' },
    { key: '3', imgUrl: 'structure_design', desc: '结构设计' },
    { key: '4', imgUrl: 'industry_design', desc: '工业设计' },
    { key: '5', imgUrl: 'appearance_design', desc: '外观设计' },
    { key: '6', imgUrl: 'brand_packing', desc: '品牌包装' },
]
const IndustryItems = (): any => {
    return areaList.map(item => {
        return (
            <div className="industry-area-item" key={item.key}>
                <div className="area-iten-img">
                    <img src={require('../../static/images/' + item.imgUrl + '.png')} alt="" />
                </div>
                <div className="area-item-desc">{item.desc}</div>
            </div>
        )
    })
}
// 三张示例图
const sample = [
    { key: '1', imgUrl: 'business_sample1' },
    { key: '2', imgUrl: 'business_sample2' },
    { key: '3', imgUrl: 'business_sample3' },
]
const Sample = (): any => {
    return sample.map(item => {
        return (
            <div className="industry-sample-item" key={item.key}>
                <img src={require('../../static/images/' + item.imgUrl + '.png')} alt="" />
            </div>
        )
    })
}

const IndustryBusiness = () => {
    return (
        <div className="industry-business">
            <div className="industry-area">
                <img src={require('../../static/images/business_area.png')} alt="" />
            </div>
            <div className="industry-area-list">
                <IndustryItems></IndustryItems>
            </div>
            <div className="industry-area-sample">
                <Sample></Sample>
            </div>
        </div>
    )
}

export default IndustryBusiness