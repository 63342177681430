import './footer.less'
import gzh from '../../static/images/gzh.png'

interface footerType{
    info:{
        name:string,
        keepRecord:string
    }
}

const Footer = ({info}:footerType)=>{
    return (
        <div className='footer-box'>
            <div className='footer-top'>
                <div className='footer-title'>
                    <div>关于我们</div>
                    <p>公司介绍</p>
                    <p>公司产品</p>
                    <p>公司动态</p>
                    <p>加入我们</p>
                </div>
                <div className='footer-title'>
                    <div>联系我们</div>
                    <p>电话：18108071931</p>
                    <p>邮箱：beijiate@163.com</p>
                    <p>地址：成都市金牛区盛大国际七栋一单元八楼</p>
                </div>
                <div className='footer-title'>
                    <div className='footer-wx'>
                        <img src={gzh} alt=""/>
                        <span>官方公众号</span>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <p>{info.name}</p>
                <p>网站备案号：{info.keepRecord}</p>
                <p></p>
            </div>
        </div>
    )
}

export default Footer;