import './electro-business.less'

// 四个主营业务
// ../../static/images/electro_sample1.png
const sampleList = [
    { key: '1', title: '印刷电路板加工', desc: '3mil线宽线距、HDI、盲埋孔0201 & BGA贴装', imgUrl: 'electro_sample1.png' },
    { key: '2', title: '电子元件采购', desc: '原厂及一级代理货源，提供COC证明、供货稳定', imgUrl: 'electro_sample2.png' },
    { key: '3', title: '成品组装', desc: '进口设备，标准化生产线，SOP作业规范', imgUrl: 'electro_sample3.png' },
    { key: '4', title: '电子电路设计', desc: '嵌入式开发、PCB Layout 设计、软件开发等', imgUrl: 'electro_sample4.png' },
]

const ElectroSample = (): any => {
    return sampleList.map(item => {
        return (
            <div className="electro-sample-item" key={item.key}>
                <div className="sample-item-avatar">
                    <img src={require('../../static/images/' + item.imgUrl)} alt="" />
                </div>
                <div className="sample-item-info">
                    <div className="sample-item-title">印刷电路板加工</div>
                    <div className="sample-item-desc">3mil线宽线距、HDI、盲埋孔0201 & BGA贴装</div>
                </div>
            </div>
        )
    })
}

const ElectroBusiness = () => {
    return (
        <div className='electro-area-box'>
            <div className="electro-area-header">
                <img src={require('../../static/images/business_area.png')} alt="" />
            </div>
            <div className="electro-area-info">
                <div className="area-info-title">
                    <div className="area-info-title-cn">业务范围</div>
                    <div className="area-info-title-en">Business</div>
                </div>
                <div className="area-info-details">
                    持续专注的提供PCB制作、电子元器件采购、SMT贴片加工、DIP插件加工、PCBA测试、成品组装等一站式电子制造服务。
                    产品覆盖通讯、计算机、工业及医疗性质等精细化高要求领域，为客户提供稳定优质的自动化、智能化和专业高效的定制化服务。
                </div>
            </div>
            <div className="electro-area-sample">
                <ElectroSample></ElectroSample>
            </div>
        </div>
    )
}

export default ElectroBusiness