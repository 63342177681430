import './about.less'

interface aboutDataType {
    info: {
        name: string,
        desc: string,
        imgUrl: string
    }
}

const About = ({info}: aboutDataType) => {
    return (
        <div className="industry-box">
            {/* 关于我们 */}
            <div className="industry-about" id="my">
                <div className="industry-about-header">
                    <img src={require('../../static/images/about_us.png')} alt="" />
                </div>
                <div className="industry-about-desc">
                    <div className="industry-company">
                        <img src={info.imgUrl} alt="" />
                    </div>
                    <div className="industry-company-info">
                        <div className="industry-info-title">{info.name}</div>
                        <div className="industry-info-text">{info.desc}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About