import './header.less'
import {useNavigate} from "react-router-dom";


//头部
interface navDataType {
    text: string,
    url: string
}

interface headerType {
    headerLogo:{
        name:string,
        english:string,
        logoUrl:string
    }
}

const Header = ({headerLogo}:headerType) => {
    const navData: Array<navDataType> = [
        {
            text: '首页',
            url: '/home'
        },
        {
            text: '电路设计',
            url: '/electronics'
        },
        {
            text: '软件开发',
            url: '/home'
        },
        {
            text: '工业设计',
            url: '/industry'
        },
        {
            text: '关于我们',
            url: '#my'
        }
    ];

    const nav = useNavigate();
    return (
        <div className="header-box">
            <div className="header_left">
                <div className="logo">
                    <img src={headerLogo.logoUrl} alt=""/>
                </div>
                <div className="header-logo-inner">
                    <p>{headerLogo.name}</p>
                    <p>{headerLogo.english}</p>
                </div>
            </div>
            <div className="header_right">
                {
                    navData.map((item:navDataType,index:number)=>{
                        return (
                            <a key={index} onClick={()=>nav(item.url)} href={item.url}>{item.text}</a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Header;